<script>
import DataChart from "./DataChart.vue";
import { getTableData } from "./orarest";
import * as MyLegend from 'chartist-plugin-legend';

export default {
  name: "DataChartPivot",
  components: {
    DataChart
  },
  extends: DataChart,
  methods: {
    async getData() {
      if (!this.inURL) return;
      const inParams = {
        periodtype: this.inPeriodtype,
        trainingtype: this.inTraingintype
      };
     const tester = new MyLegend()
     const retarr = await getTableData(this.inURL, inParams);
     for (var key in retarr[0]) {
        // const eserie = { name: key, data: [] };
        //this.ChartData.data.series.push(eserie);
        this.ChartData.data.series.push([]);
      }
      // convert from table to chart series
      retarr.map(ser => {
        let i = 0;
        for (var key in ser) {
          // this.ChartData.data.series[i++].data.push(ser[key]);
          this.ChartData.data.series[i++].push(ser[key]);
        }
      });
      this.ChartData.data.labels = this.ChartData.data.series[0];
      this.ChartData.data.series.splice(0, 1);
      if (
        this.ChartData.data.labels.length > process.env.VUE_APP_X_LABEL_LONG
      ) {
        let sl = Math.round(
          this.ChartData.data.labels.length / process.env.VUE_APP_X_LABEL_LONG
        );
        this.ChartData.data.labels.forEach(function(part, index) {
          if (!(index % sl === 0)) this[index] = "";
        }, this.ChartData.data.labels);
      }
      if (this.inLine === "Bar") {
        this.ChartData.options = {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 300,
          showPoint: false,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          /* plugins: [
            Chartist.plugins.legend({
              onClick: function() {
                alert("Somebody clicked a legend!");
              }
            })
          ] */
        };
      }
      if (this.inLine === "Pie") {
        this.ChartData.data.labels = "";
        this.ChartData.data.labels = [];
        this.ChartData.options = {};
        this.ChartData.data.series = this.ChartData.data.series[0];
        this.ChartData.options.InterpolationFnc.value = `${value}%`;
      }
      this.setOptions;
    }
  }
};
</script>
