<template>
  <base-material-card :color="inColor" :icon="inIcon" inline class="px-5 py-3">
    <template v-slot:after-heading>
      <h4 class="display-1 font-weight-bold mt-2">{{inTitle}}</h4>
      <div class="grey--text font-weight-light">
        <span class="subtitle-1" v-html="inText" />
      </div>
    </template>
    <v-data-table mobile-breakpoint="300" :headers="columnheaders" :items="retarr" :search.sync="search" dense>
      <!-- template slot="headerCell" slot-scope="{ header }">
        <span class="subheading font-weight-light text--darken-3" v-text="header.text" />
      </template>
      <template slot="items" slot-scope="{ item }">
        <td
          v-for="(value, key, index) in item"
          :key="columnheaders[index].value"
          :class="columnheaders[index].alignclass"
        >{{ 3 === 2 ? value : 'abc' }}</td>
      </template -->
      <!--     <v-data-table :headers="columnheaders" :items="retarr" show-select
        :search.sync="search"
        :sort-by="['name', 'office']"
        :sort-desc="[false, true]"
        multi-sort
     >  template v-slot:footer>
          <v-card-text>
            <v-row
              align="center"
              justify="end"
            >
              <strong class="title">Total</strong>
              <div class="display-1 font-weight-light ml-10">
                € 12,999
              </div>
            </v-row>
          </v-card-text>
      </template-->
    </v-data-table>
  </base-material-card>
</template>
<script>
import { getTableData, formatHeaders } from "./orarest";

export default {
  props: {
    inAction: {
      type: Boolean,
      default: false
    },
    inTitle: {
      type: String,
      default: "-"
    },
    inText: {
      type: String,
      default: ""
    },
    inColor: {
      type: String,
      default: "#0b4168"
    },
    inIcon: {
      type: String,
      default: "mdi-clipboard-text"
    },
    inClass: {
      type: String,
      default: "gTableM"
    },
    inURL: {
      type: String,
      default: ""
    },
    inPeriodtype: {
      type: String,
      default: "m"
    },
    inTraingintype: {
      type: String,
      default: "1001"
    }
  },
  data: () => ({
    retarr: [],
    columnheaders: [],
    search: undefined
  }),
  mounted() {
    this.getSelectData();
  },
  methods: {
    async getSelectData() {
      var inParams = {
        periodtype: this.inPeriodtype,
        trainingtype: this.inTraingintype
      };
      this.retarr = await getTableData(this.inURL, inParams);
      this.columnheaders = formatHeaders(Object.keys(this.retarr[0]));
    }
  }
};
</script>
<style lang="scss" scoped>
.gTableM table tr {
  font-size: 3em !important;
}
.gTableM table th {
  font-size: 5em !important;
  font-weight: bold !important;
}

.gTableS table tr {
  font-size: 3.5em;
}
.gTableS table th {
  font-size: -5em;
  font-weight: bold;
}
</style>