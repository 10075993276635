<template>
  <base-material-chart-card
    :data="ChartData.data"
    :options="ChartData.options"
    :color="inColor"
    :type="inLine"
    class="px-4 py-3"
  >
    <h4 class="display-1 font-weight-light mt-2">{{inTitle}}</h4>
    <div class="grey--text font-weight-light">
      <span class="subtitle-1" v-html="inSubTitle" />
    </div>
  </base-material-chart-card>
</template>
<script>
import DataChartPivot from "./DataChartPivot.vue";

export default {
  name: "DataSmallChart",
  components: {
    DataChartPivot
  },
  extends: DataChartPivot,
  methods: {
  }
}
</script>
<style lang="sass">
.ct-text
    color: #000000
.ct-series-a .ct-bar 
    stroke: #689CC0
    color: #689CC0 
.ct-series-b .ct-bar 
    stroke: #C94B45 
.ct-series-c .ct-bar
    stroke: #32b233
.ct-series-d .ct-bar
    stroke: #FF91AB
.ct-series-e .ct-bar
    stroke: #658DA6
.ct-label 
    font-size: 25px
    color: #000000

</style>