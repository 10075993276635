<template>
  <base-material-card
    id="pie1"
    :color="inColor"
    icon="mdi-chart-pie"
    :title="inTitle"
    class="px-4 py-3"
  >
    <chartist
      :data="ChartData.data"
      :options="ChartData.options"
      type="Pie"
      style="max-height: 250px;"
    />
    <v-divider class="ma-3" />
    <div class="px-3">
      <div class="body-2 text-uppercase grey--text font-weight-bold mb-3">Legend</div>
      <v-row align="center" class="ma-0">
        <div v-for="(leg, i) in legends" :key="i">
          <v-avatar class="mr-1" :color="colorarr[i]" size="11" />
          <span class="mr-3 font-weight-light">{{leg}}</span>
        </div>
      </v-row>
    </div>
  </base-material-card>
</template>
<script>
import DataChartPivot from "./DataChartPivot.vue";
import { getTableData } from "./orarest";

export default {
  name: "DataChartPie",
  components: {
    DataChartPivot
  },
  extends: DataChartPivot,
  data() {
    return {
      colorarr: ["#689CC0", "#C94B45", "#734D65", "#FF91AB", "#658DA6"],
      legends: []
    };
  },
  methods: {
    async getData() {
      if (!this.inURL) return;
      var inParams = {
        periodtype: this.inPeriodtype,
        trainingtype: this.inTraingintype
      };
      var retarr = await getTableData(this.inURL, inParams);
      for (var key in retarr[0]) {
        this.ChartData.data.series.push([]);
      }
      // convert from table to chart series
      retarr.map(ser => {
        let i = 0;
        for (var key in ser) {
          this.ChartData.data.series[i++].push(ser[key]);
        }
      });
      this.legends = this.ChartData.data.series[0];
      this.ChartData.data.series.splice(0, 1);
      this.ChartData.data.series = this.ChartData.data.series[0];
      this.ChartData.data.labels = [];
      this.ChartData.options = {};
      //this.ChartData.options.labelInterpolationFnc.value = `${value}%`
    }
  }
};
</script>
<style lang="sass">
.ct-text
    color: #000000
.ct-series-a .ct-slice-pie
    fill: #689CC0
    stroke: #689CC0
    color: #689CC0
    stroke-width: 0px
.ct-series-b .ct-slice-pie
    fill: #C94B45
    stroke: #011126
    stroke-width: 0px
.ct-series-c .ct-slice-pie
    fill: #734D65
.ct-series-d .ct-slice-pie
    fill: #FF91AB
.ct-series-e .ct-slice-pie
    fill: #658DA6
.ct-label 
    font-size: 25px
    color: #000000

</style>