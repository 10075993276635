<template>
  <v-col cols="12" lg="5">
    <base-material-card :color="inColor" inline :title="inTitle" class="px-5 py-3">
      <v-subheader class="display-1 mt-3">
        <span class="subtitle-1" v-html="inText" />
      </v-subheader>
      <v-form class="pt-1" @submit.prevent="onSubmit">
        <v-menu
          ref="dateselect"
          v-model="dateselect"
          :close-on-content-click="true"
          :return-value.sync="seldate"
          transition="scale-transition"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="sendProgram.newExcercise.inputday"
              color="info"
              label="Select date"
              prepend-icon="mdi-calendar-outline"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="sendProgram.newExcercise.inputday"
            color="secondary"
            :max="today"
            landscape
            scrollable
            class="ma-4"
          >
            <v-spacer />
            <v-btn color="secondary" large @click="dateselect = false">Cancel</v-btn>
          </v-date-picker>
        </v-menu>
        <v-select
          v-model="sendProgram.newExcercise.exercises"
          color="secondary"
          item-color="secondary"
          return-object
          label="Select exercises"
          multiple
          :items="retarr"
          item-value="id"
          item-text="trainingname"
        >
          <template v-slot:item="{ attrs, item, on }">
            <v-list-item
              v-bind="attrs"
              active-class="secondary elevation-4 white--text"
              class="mx-3 mb-3 v-sheet"
              elevation="0"
              v-on="on"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.trainingname" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <v-text-field v-model="sendProgram.newExcercise.exLocation" label="Location" />
        <v-text-field
          v-model="sendProgram.newExcercise.exDuration"
          label="Duration"
          hint="mi:ss ex 67:12 for 1 hour, 7 minutes and 12 secons"
        />
        <!-- vue-timepicker v-model="exDuration2" format="HH:mm:ss"></vue-timepicker -->
        <v-subheader class="display-1 mt-3">&nbsp;</v-subheader>
        <v-slider
          v-model="sendProgram.newExcercise.distanceSlider"
          prepend-icon="mdi-airplane-takeoff"
          thumb-label="always"
          max="25000"
          min="0"
          color="secondary"
        />
        <v-slider
          v-model="sendProgram.newExcercise.intensitySlider"
          prepend-icon="mdi-axe"
          thumb-label="always"
          color="lighten-4"
          max="10"
          min="0"
          track-color="info lighten-4"
        />
        <v-row no-gutters class="ml-4">
          <v-col cols="46">
            <div class="pa-3 text-center">
              <v-btn :color="inColor" name="sendform" default type="submit" :disabled="isLoading">
                <span class="subtitle-1" v-html="inSubmit" />
              </v-btn>
            </div>
          </v-col>
          <v-col cols="4">
            <v-switch input-value="true" disabled :label="textLoading" :loading="showLoading" />
          </v-col>
        </v-row>
        <v-row no-gutters class="ml-4">
          <v-col cols="4">
            <v-switch input-value="true" label="Strength" value />
          </v-col>
          <v-col cols="4">
            <v-switch input-value="true" label="Cardio" value />
          </v-col>
          <v-col cols="4">
            <v-switch input-value="true" disabled :label="textLoading" :loading="showLoading" />
          </v-col>
        </v-row>
      </v-form>
      <v-snackbar
        v-model="retSnackbar.show"
        :bottom="retSnackbar.y === 'bottom'"
        :color="retSnackbar.color"
        :left="retSnackbar.x === 'left'"
        :multi-line="retSnackbar.mode === 'multi-line'"
        :right="retSnackbar.x === 'right'"
        :timeout="retSnackbar.timeout"
        :top="retSnackbar.y === 'bottom'"
        :vertical="retSnackbar.mode === 'vertical'"
      >{{ retSnackbar.text }}
        <v-btn dark text @click="retSnackbar.show = false">Close</v-btn>
      </v-snackbar>
      <DataTableStrip inURL="/exercises/stats/21" :key="componentKey" inClass="gTableS" />
    </base-material-card>
  </v-col>
</template>
<script>
import { getTableData, runPostRow } from "./orarest";
import DataTableStrip from "./DataTableStrip.vue";

const apicall = { program: "newExercise" };
const initExcercise = {
  inputday: new Date().toISOString().substr(0, 10),
  exercises: "",
  exLocation: "",
  exDuration: "",
  distanceSlider: 0,
  intensitySlider: 4
};
export default {
  name: "FormTraining",
  components: {
    DataTableStrip
  },
  props: {
    inAction: {
      type: Boolean,
      default: false
    },
    inTitle: {
      type: String,
      default: "Training Form"
    },
    inText: {
      type: String,
      default: "Record your excercise activity"
    },
    inColor: {
      type: String,
      default: "deep"
    },
    inIcon: {
      type: String,
      default: "mdi-clipboard-text"
    },
    inURL: {
      type: String,
      default: ""
    },
    inSubmit: {
      type: String,
      default: "Save Exercise"
    }
  },
  data() {
    return {
      today: new Date().toISOString().substr(0, 10),
      seldate: "",
      dateselect: false,
      textLoading: "",
      showLoading: "false",
      isLoading: null,
      sendProgram: {newExcercise: Object.assign({}, initExcercise)},
      // newExcercise: Object.assign({}, initExcercise),
      retarr: [],
      componentKey: "-",
      retSnackbar:{
        show: false,
        color: 'info',
        timeout: 5000,
        text: 'Your new execercise is logged',
        mode: '',
        x: null,
        y: 'bottom',
      },
      snackErr:{
        show: true,
        color: 'error',
        timeout: 8000,
        text: 'Something went wrong - please try again...',
        mode: '',
        x: null,
        y: 'bottom'
      }
    };
  },
  mounted() {
    this.getSelectData();
    //this.sendProgram.newExcercise = this.newExcercise;
  },
  methods: {
    forceRerender() {
      this.componentKey += "-";
    },
    async getSelectData() {
      this.retarr = await getTableData("/exercises/stats/50", null);
      console.info("FT gTD json: " + this.retarr);
    },
    async onSubmit() {
      this.showLoading = "warning";
      this.textLoading = "loading";
      this.isLoading = true;
      const sendURL = "/exercises/trainingtypes";
      const resp = await runPostRow(sendURL, this.sendProgram);
      if (resp.status === 200) 
        this.sendProgram.newExcercise = Object.assign({}, initExcercise)
      else this.retSnackbar = this.snackErr
      this.retSnackbar.show=true;
      this.showLoading = false;
      this.textLoading = "";
      setTimeout(() => {this.isLoading = false;}, 1000);
      this.forceRerender();
    }
  }
};
</script>
