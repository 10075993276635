<template>
  <base-material-card
    id="coloured-line"
    :color="inColor"
    :icon="inIcon"
    class="px-4 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light mt-2">
        {{inTitle}}
        <span class="subtitle-1" v-html="getSubTitle" />
      </div>
    </template>

    <chartist
      :data="ChartData.data"
      :options="ChartData.options"
      :type="inLine"
      style="max-height: 150px;"
      class="mt-3"
    />
  </base-material-card>
</template>

<script>
import { getTableData} from "./orarest";

export default {
  name: "DataChart",
  props: {
    inTitle: {
      type: String,
      default: "Title"
    },
    inSubTitle: {
      type: String
    },
    inColor: {
      type: String,
      default: "warning"
    },
    inURL: {
      type: String,
      default: ""
    },
    inLine: {
      type: String,
      default: "Line"
    },
    inIcon: {
      type: String,
      default: "mdi-chart-timeline-variant"
    },
    inMin:  {
      type: String,
      default: '0'
    },
    inLrg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ChartData: {
        data: {
          labels: [],
          series: []
        },
        options: {
          low: parseInt(this.inMin),
          high: 30, 
          axisX: {
            showGrid: false
          },
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          showPoint: false
        }
      }
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    getSubTitle() {
      return this.inSubTitle ? ' &mdash; ' + this.inSubTitle : ''
    },
    setOptions(){
      // this.ChartData.options.high = Math.max(...this.ChartData.data.series) * 1.2
      this.ChartData.options.high = Math.max.apply(null, this.ChartData.data.series.map(function(row){ return Math.max.apply(Math, row); })) * 1.2      
    this.ChartData.options.low = Math.min.apply(null, this.ChartData.data.series.map(function(row){ return Math.min.apply(Math, row); })) * 1.2
    if (this.ChartData.options.low>0)this.ChartData.options.low=0      
      // if (this.inLine === 'Pie') this.ChartData.labels = {}      
    }
  },
  methods: {
    async getData() {
      if (!this.inURL) return
      var retarr = await getTableData(this.inURL);
      this.ChartData.data.labels =  Object.keys(retarr[0])
      retarr.forEach (element => {
        this.ChartData.data.series.push(Object.values(element))
      })
      this.setOptions     
    }
  }
}
</script>
<style lang="sass">
  #coloured-line
    .ct-series .ct-line
      stroke-width: 1.15px !important
    .ct-series-b .ct-line
      stroke: #333333
    .ct-series-c .ct-line
      stroke: #FF41AB
      stroke-width: 1.9px !important
</style>