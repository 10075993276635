<template>
  <div>
    <v-data-table :headers="columnheaders" :items="retarr" hide-default-footer dense>
      <template slot="headerCell" slot-scope="{ header }">
        <span class="subheading font-weight-bold text--darken-3" v-text="header.text + '.'" />
      </template>
      <template v-slot:item="props">
        <tr>
          <td
            v-for="(value, key, index) in props.item"
            :key="columnheaders[index].value"
            :class="columnheaders[index].alignclass"
          >
            <TableButton
              @delItem="delItem"
              v-if="columnheaders[index].btn === 'del'"
              :inID="value"
            />
            <div v-else>{{value}}</div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="retSnackbar.show"
      :bottom="retSnackbar.y === 'bottom'"
      :color="retSnackbar.color"
      :left="retSnackbar.x === 'left'"
      :multi-line="retSnackbar.mode === 'multi-line'"
      :right="retSnackbar.x === 'right'"
      :timeout="retSnackbar.timeout"
      :top="retSnackbar.y === 'bottom'"
      :vertical="retSnackbar.mode === 'vertical'"
    >
      {{ retSnackbar.text }}
      <v-btn dark text @click="retSnackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";
import TableButton from "./TableButton.vue";
import { runPost } from "@/views/dashboard/components/core/orarest";

export default {
  name: "DataTableStrip",
  extends: DataTable,
  data() {
    return {
      retSnackbar: {
        show: false,
        color: "warning",
        timeout: 6000,
        text: "Excercise deleted.",
        mode: "",
        x: null,
        y: "bottom"
      }
    };
  },
  components: {
    TableButton
  },
  methods: {
    async delItem(inID) {
      var sendParams = {};
      sendParams.delExercise = {};
      sendParams.delExercise.trainingid = inID;
      const response = await runPost(sendParams);
      console.info("gex delItem result: ", response);
      if (response.status == 200) {
        this.retSnackbar.show = true;
        this.getSelectData();
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    }
  }
};
</script>