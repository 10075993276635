<template>
  <v-container fluid>
    <v-row>
      <FormTraining />
      <v-col cols="12" lg="4">
        <DataSmallChart inLine="Bar" inColor="accent" inURL="/exercises/stats/62" inTitle="Sums Pr week" inSubTitle="last 6 weeks"  />  
        <div class="py-3" />
        <DataSmallChart inLine="Bar" inColor="blend" inURL="/exercises/stats/63" inTitle="Sums Pr month" inSubTitle="last 6 months"  />  
        <DataSmallChart inLine="Bar" inColor="primary" inURL="/exercises/stats/65" inTitle="Avg pr week previous month" inSubTitle="last 6 months"  />  
        <div class="py-3" />
       <div class="py-3" />
      <DataChartPie inColor="success" inURL="/exercises/stats/60" inTitle="Categories Last Month"  />
      </v-col>
      <v-col cols="12" lg="3">
        <DataTable inURL="/exercises/stats/100" inTitle="Sum pr Month" inText="Latest + top ten" inTraingintype="1001" inPeriodtype="m" />
        <div class="py-3" />
        <DataSmallChart inLine="Bar" inColor="blend" inURL="/exercises/stats/61" inTitle="Excercise pr day" inSubTitle="+/- percentage of average"  />  
        <div class="py-3" />
        <DataTable inURL="/exercises/stats/100" inTitle="Ski pr year" inText="Current first" inTraingintype="1005" inPeriodtype="y" />
        
      </v-col>
      <v-col cols="12" lg="12">
        <div class="py-3" />
        <DataChartPivot
          inURL="/exercises/stats/10"
          inIcon="mdi-chart-timeline-variant"
          inSubTitle="last half year"
          inTitle="Week Stats"
        />
        <DataChartPivot
          inURL="/exercises/stats/11"
          inSubTitle="last half year"
          inTitle="Month Stats"
        />
        <DataChartPivot
          inURL="/exercises/stats/12"
          inSubTitle="last half year"
          inTitle="1/2 year Stats"
        />
        <DataChartPivot
          inURL="/exercises/stats/13"
          InSubTitle="historical"
          inTitle="1/2 year Stats"
        />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataChart from "./components/core/DataChart.vue";
import DataChartPivot from "./components/core/DataChartPivot.vue";
import DataChartPie from "./components/core/DataChartPie.vue";
import DataTable from "./components/core/DataTable.vue";
import DataSmallChart from "./components/core/DataSmallChart.vue";
import FormTraining from "./components/core/FormTraining.vue";

export default {
  components: {
    DataChart,
    DataChartPivot,
    DataTable,
    DataSmallChart, 
    DataChartPie,
    FormTraining
  }
  /* mounted () {
    this.$cookies.set('gothar', 'myuser','180d')
    this.$cookies.config('180d')
    this.$cookies.set("gex_session","25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX", "8d")
    this.$cookies.set("token","GH1.1.1689020474.1484362314","180d") 

  }, */

};
</script>
