<template>
  <div>
      <v-btn :color="inColor" class="ml-1" fab icon x-small @click="$emit('delItem', inID)">
        <v-icon small v-text="inIcon" />
      </v-btn>
  </div>
</template>
<script>

export default {
  name: "TableButton",
  props: {
    inColor: {
      type: String,
      default: "error"
    },
    inIcon: {
      type: String,
      default: "mdi-close"
    },
    inCommand: {
      type: String,
      default: ""
    },
    inID: {
      type: Number
    }
  }
};
</script>
